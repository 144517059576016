.figure {
    float: right;
    margin: 10px 00px 10px 20px;

    display: flex;
    flex-direction: column;
    align-items: center;

    .figure-container {

    }

    .footer {
        font-size: 0.8rem;;
    }
}