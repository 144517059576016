.sidebar {
    overflow: hidden;
    background-color: unset;
    padding: unset; // cannot pad here because of background-image

    .main-logo {
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            max-width: 150px;
        }
    }

    .sidebar-content {
        padding: 3rem 1rem; // re-apply padding from sidebar
        color: rgba(255, 255, 255, 0.9); // better readability for logo

        // allow scrolling within this div
        max-height: 100%;
        overflow-y: auto;
    }

    .associated-logos {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 50px;

        .logos {
            padding-top: 20px;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;

            $logo-padding: 10px;

            margin: 0px -$logo-padding;

            .logo {
                padding: 0px $logo-padding;

                img {
                    border-radius: 0;
                    height: 55px;
                }
            }
        }

        .logos.wide {
            flex-direction: column;
            align-items: center;
        }

        .divider {
            margin-top: 20px;
        }

        .broughttoyouby {
            font-size: 12pt;
            font-weight: 100;
        }
    }

    .lead {
        text-align: center;
    }

    .sidebar-nav {
        text-align: center;
    }

    .sidebar-background {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: -1;
        background-color: #004F7B;
    }
}


@media (min-width: 48em) {
    .sidebar {
        position: fixed;
        left: 0;
        bottom: 0;
        height: 100vh;
        top: unset;
        width: 16rem;

        .sidebar-nav {
            .sidebar-nav-item {
                font-size: 22px;
            }
        }
    }
}


@media (min-width: 58em) {
    .sidebar {
        .sidebar-nav {
            .sidebar-nav-item {
                font-size: 24px;
            }
        }
    }
}



tbody tr:nth-child(even) td, tbody tr:nth-child(even) th, thead tr td, thead tr th {
    // explicitly fill even rows white so the background doesn't shine through
    // odd rows are filled with #f9f9f9 by Hyde already
    background-color: #ffffff;
}



.no-break {
    white-space: nowrap;
}


.dependable-quote {
    text-align: center;
    font-style: italic;
}


.reference {
    position: relative;
    height: 100%;
    width: 100%;
}


.absolute-full {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}


.overflow-y-auto {
    overflow-y: auto;
}

.clearfix::after {
    display: block;
    content: "";
    clear: both;
}

p.lead span {
    font-weight: bold;
    font-size: 42pt;
}


.cancellation-notice-box,.green-box {
    position: relative;
    padding: .75rem 1.25rem;
    margin-bottom: 1rem;
    border-radius: .25rem;
    border: 1px solid;
}

.cancellation-notice-box {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
}

.green-box {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
}
